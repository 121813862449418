<template>
  <b-container>
    <h1>Magic Links</h1>
    <p v-if="reminder">
      After sharing a savings report magic link, please make sure to manually set the report shared
      state to the date you shared it on (Savings report -> Admin options -> Update shared date)
    </p>
    <b-form v-on:submit.prevent="createMagicLink">
      <b-row>
        <b-col md="6">
          <b-form-group id="input.username" label="Email/Username:" label-for="input.username">
            <b-form-input
              id="input.username"
              v-model="input.username"
              v-bind:disabled="busy"
              v-on:keydown.enter.prevent
              :formatter="lowercaseformatter"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group id="input.redirect" label="Redirect:" label-for="input.redirect">
            <b-form-input
              id="input.redirect"
              v-model="input.redirect"
              v-bind:disabled="busy"
              v-on:keydown.enter.prevent
              :formatter="lowercaseformatter"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row-reverse">
        <b-button
          class="mr-3"
          id="createMagicLink"
          v-bind:disabled="busy"
          type="submit"
          variant="primary"
          >Create</b-button
        >
      </b-row>
    </b-form>
    <b-form>
      <b-row>
        <b-col md="6">
          <b-form-group
            id="output.username"
            label="Magic Link Username:"
            label-for="output.username"
          >
            <b-form-input
              id="output.username"
              v-model="output.username"
              disabled
              v-on:keydown.enter.prevent
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="output.redirect"
            label="Magic Link Redirect:"
            label-for="output.redirect"
          >
            <b-form-input
              id="output.redirect"
              v-model="output.redirect"
              disabled
              v-on:keydown.enter.prevent
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="magiclink" label="Magic Link:" label-for="magiclink">
            <b-form-input
              id="magiclink"
              v-model="output.magiclink"
              disabled
              v-on:keydown.enter.prevent
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-row-reverse">
        <b-button
          class="mr-3"
          id="copyToClipboard"
          variant="outline-secondary"
          v-clipboard:copy="output.magiclink"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
          >Copy to Clipboard</b-button
        >
      </b-row>
    </b-form>
  </b-container>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";

export default {
  name: "MagicLinks",
  data() {
    return {
      title: "Reducer - Magic Links",
      input: {
        redirect: "savings/XXXXX",
        username: ""
      },
      output: {
        redirect: "",
        username: "",
        magiclink: ""
      },
      busy: false,
      reminder: false
    };
  },
  methods: {
    async createMagicLink() {
      this.busy = true;
      this.reminder = false;
      try {
        let client = await ApiHelper.http();
        await client
          .post(`${ApiHelper.endPoint()}magiccodes/createMagicLink`, {
            redirect: this.input.redirect,
            username: this.input.username
          })
          .then((response) => {
            Console.log(response);
            this.output.redirect = this.input.redirect;
            this.output.username = this.input.username;
            this.output.magiclink = response.data.magicLink;
            this.showSuccess("Magic Link created");
            this.resetInputs();
            this.reminder = true;
          })
          .catch((error) => {
            Console.log(error);
            this.resetOutputs();
            this.showWarning("Magic Link creation failed: " + error);
          });
      } catch {
        this.resetOutputs();
        this.showWarning("Magic Link creation failed");
      } finally {
        this.busy = false;
      }
    },
    resetInputs() {
      this.input.redirect = "savings/XXXXX";
      this.input.username = "";
    },
    resetOutputs() {
      this.output.redirect = "";
      this.output.username = "";
      this.output.magicLink = "";
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    showSuccess(message) {
      EventBus.$emit("show-toast", { message: message, variant: "success" });
    },
    copySuccess() {
      this.showSuccess("Magic Link copied");
    },
    copyError() {
      this.showWarning("Magic Link could not be copied");
    },
    lowercaseformatter(value) {
      return value.toLowerCase().trim();
    }
  }
};
</script>
